import React from 'react'
import PageLayout from '../components/PageLayout';
import ProjectCoverListContainer from '../containers/ProjectCoverListContainer';

function Music() {
  return (
    <PageLayout title="Exhibition" uppercaseTitle titleAnim>
      <ProjectCoverListContainer category="exhibition"/>
    </PageLayout>
  );
}

export default Music;
